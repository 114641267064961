import * as React from "react";
import { Parallax } from "react-parallax";

import image from "../images/world.jpg";
import Layout from "../components/layout";
import Section from "../components/section";
import Typography from "../components/typography";
import { Row, Container, Col } from "react-bootstrap";
import ScheduleForm from "../components/scheduleForm";

const LoanOfficers = () => {
  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Section className="partner-page-container" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
          <Row className="d-flex h-100">
            <Col className= "PartnerCol">
              <Typography type="head" className="nom">
                Connections For Strategic
                Partnership through Fundingverse
              </Typography>
              <Typography type="head" className="hide-on-desktop text-center">
                Earn Commission For Referrals
              </Typography>
            </Col>
            <Col>
              <ScheduleForm to="/" />
            </Col>
          </Row>
        </Parallax>
      </Section>
      <Section size="sm" color="light" bg="dark">
        <Container className="py-5">
          <Typography type="title">
            Refer businesses in your network to us &amp; earn commissions:
          </Typography>
          <ul className="text-start text-light">
            <li className="text-light">
              We offer commissions for every lead you send our way that ends up
              being funded.
            </li>
            <li className="text-light">
              We offer a singular process and a simplistic journey for your
              introductions to find the type of funding they need.
            </li>
            <li className="text-light">
              Have instant real-time access to the process &amp; progress, so
              you can serve as an all-in-one lending solution for your
              relationships.
            </li>
            <li className="text-light">
              We’d like to partner with you, schedule a time to talk so we can
              learn more about each other!
            </li>
          </ul>
        </Container>
      </Section>
    </Layout>
  );
};

export default LoanOfficers;
